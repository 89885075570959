<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-account-outline"
          style="height: 600px;"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('compteClient.compte_client') }}
              <span class="body-1" />
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="compteClient.dateCreation"
                    :label="$t('compteClient.date_de_creation')"
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="compteClient.nom"
                    data-cy="nomCompteClient"
                    :label="$t('compteClient.nom_du_compte')"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="compteClient.description"
                    data-cy="descriptionCompteClient"
                    class="purple-input"
                    :label="$t('general.description')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <template v-slot:actions>
            <v-btn
                color="primary"
                class="mr-0"
                data-cy="btnSauvegarderCompteClient"
                @click="onSauvegarder()"
              >
                {{ $t('general.sauvegarder') }}
              </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
          <v-card height="600px">
            <profile-picure-card
              v-model="ccMediaLogo"
              @majImage="sauvegardeLogo()"
              @suppImage="suppressionLogo()"
            />
            <v-card-text>
              <v-data-table
                :caption="$t('compteClient.responsables')"
                :headers="headers"
                :items="compteClient.listeResponsables"
                :no-data-text="$t('compteClient.aucun_responsable')"
                dense
                data-cy="dtResponsable"
                :footer-props="{
                  itemsPerPageAllText: $t('tout'),
                  itemsPerPageText: $t('general.lignes_par_page'),
                  pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    class="px-2 ml-1 warning"
                    min-width="0"
                    small
                    @click="onEnleverResponsable(item.id)"
                  >
                    <v-icon small>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions
              data-cy="cardAjouterResponsable"
            >
              <selection-usager
                @usager-selection="onResponsableIdentifié($event)"
              />
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import SelectionUsager from './../../composantes/SelectionUsager'
  import i18n from '@/i18n.js'
  import ProfilePicureCard from '../../../components/base/ProfilePicureCard.vue'

  export default {
    components: {
      SelectionUsager,
      ProfilePicureCard,
    },
    data: () => ({
      compteClient: {},
      headers: [
        { text: i18n.t('general.prenom'), value: 'usager.prenom' },
        { text: i18n.t('general.nom_de_famille'), value: 'usager.nom' },
        { text: i18n.t('general.actions'), value: 'actions' },
      ],
      ccMediaLogo: {},
    }),
    mounted () {
      this.chargerDonnées()
    },
    methods: {
      chargerDonnées() {
        restApiService
          .get(`/api/compte-clients/${this.$route.params.idCompteClient}`)
          .then(result => {
            this.compteClient = result.data
            if (this.compteClient.mediaImage) {
              restApiService.getMedia(this.compteClient.mediaImage).then(m => {
                this.ccMediaLogo = m.data
              })
            }
          })
          .catch(erreur => alert(erreur))
      },
      chargerResponsables () {
        restApiService
          .get(`/api/compte-clients/${this.$route.params.idCompteClient}`)
          .then((result) => {
            this.compteClient.listeResponsables = result.data.listeResponsables
          })
          .catch((erreur) => alert(erreur))
      },
      onSauvegarder () {
        if (this.ccMediaLogo.id) {
          this.compteClient.mediaImage = `/${this.ccMediaLogo.id}/${this.ccMediaLogo.nom}`
        }
        restApiService
          .put('/api/compte-clients', this.compteClient)
          .then(response => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => {
            this.$dialog.error({
              text: i18n.t('general.une_erreur_s_est_produite_lors_de_la_sauvegarde'),
              title: i18n.t('general.erreur'),
            })
          })
      },
      sauvegardeLogo() {
        restApiService
          .put(`/api/compte-clients/${this.compteClient.id}/mediaImage`, this.ccMediaLogo)
          .then(response => {
            this.ccMediaLogo = response.data
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => {
            this.$dialog.error({
              text: i18n.t('general.une_erreur_s_est_produite_lors_de_la_sauvegarde'),
              title: i18n.t('general.erreur'),
            })
          })
      },
      suppressionLogo() {
        restApiService
          .delete(`/api/compte-clients/${this.compteClient.id}/mediaImage/${this.ccMediaLogo.id}`)
          .then(response => {
            this.$dialog.notify.success(i18n.t('general.sauvegarde'))
          })
          .catch((erreur) => {
            this.$dialog.error({
              text: i18n.t('general.une_erreur_s_est_produite_lors_de_la_sauvegarde'),
              title: i18n.t('general.erreur'),
            })
          })
      },
      onEnleverResponsable(affId) {
        restApiService
          .delete(`/api/aff-resp-compte-clients/${affId}`)
          .then(() => {
            this.chargerResponsables()
          })
          .catch(erreur => alert(erreur))
      },
      onResponsableIdentifié(e) {
        const info = {
          compteClient: {
            id: this.compteClient.id,
          },
          usager: {
            id: e.id,
          },
          creePar: 'Toucan Client',
        }
        restApiService
          .post('/api/aff-resp-compte-clients', info)
          .then(() => {
            this.chargerResponsables()
          })
          .catch(erreur => alert(erreur))
      },
    },
  }
</script>
